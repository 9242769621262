<template>
  <div class="ob-ed">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new w-100">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img src="@/assets/images/onboarding/planet.svg" alt="location" />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.location.heading") }}
            </h2>
            <div class="ob-main__sec">
              <b-form @submit="onNext">
                <ValidationObserver v-slot="{ invalid }">
                  <b-row class="ob-sec__location">
                    <b-col cols sm="12" md="5" class="ob-responsive__view">
                      <ValidationProvider
                        name="Country"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <LxpInput
                          type="text"
                          v-model="searchCountry"
                          @input="getCountry"
                          :label="$t('onboarding.location.country.hint')"
                          :placeholder="$t('onboarding.location.country.hint')"
                          :isFloating="true"
                          prepend='<img src="https://files.lxp.academy.who.int/didactica/assets/images/learning-space/main/expand.svg" alt="down-arrow"></img>'
                          @click.native.stop="isInputFocused = true"
                        />
                        <div
                          ref="dropdown"
                          v-show="isInputFocused"
                          class="dropdown-country mt-2"
                        >
                          <div
                            class="search-results"
                            v-if="searchResults.length"
                          >
                            <ul
                              v-for="(country, index) in searchResults"
                              :key="index"
                              class="countries-list__dropdown pl-1 pr-1 mt-0 mb-0"
                            >
                              <li
                                v-html="highlightMatch(country.name)"
                                class="pl-2 pt-2 pb-2 pr-2"
                                @click="selectCountry(country)"
                              ></li>
                            </ul>
                          </div>
                          <div class="no-results text-center pt-2" v-else>
                            <img
                              src="@/assets/images/onboarding/no-results.svg"
                              width="32"
                              alt="no results"
                            />
                            <p class="pt-2">
                              {{ $t("onboarding.location.country_not_found") }}
                            </p>
                          </div>
                        </div>

                        <span class="error mb-2">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                    <b-col cols sm="12" md="5" class="ob-responsive__view">
                      <ValidationProvider
                        name="City/State"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <CInput
                          id="city"
                          :label="$t('onboarding.location.city.label')"
                          :placeholder="$t('onboarding.location.city.label')"
                          :required="true"
                          :value="form.city"
                          @input="e => (form.city = e)"
                        />
                        <span class="error mb-2">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <Footer
                    :invalid="invalid || !isvalidCountry"
                    :buttonText="$t('general.next')"
                  />
                </ValidationObserver>
              </b-form>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingLayout from "../Layout/OnboardingLayout";
import OnboardingMixin from "@/components/Onboarding/mixin";
import Footer from "@/components/Layout/OnboardingPageLayout/Footer.vue";
import { LxpInput } from "didactica";
export default {
  mixins: [OnboardingMixin],
  components: { OnboardingLayout, LxpInput, Footer },
  data() {
    return {
      key: 0,
      isCompleted: false,
      form: {
        country: null,
        city: ""
      },
      currentStep: 0,
      showFreeText: false,
      searchCountry: "",
      selectedCountry: null,
      searchResults: [],
      isInputFocused: false
    };
  },
  computed: {
    ...mapGetters(["countries", "allProfile"]),
    isvalidCountry() {
      return this.countries.find(
        country => country.name === this.searchCountry
      );
    }
  },
  mounted() {
    this.currentStep = this.step;
    this.initData();
  },
  created() {
    document.addEventListener("click", this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleOutsideClick);
  },
  methods: {
    initData() {
      if (Object.keys(this.allProfile).length === 0) {
        this.$store.dispatch("getProfile").then(() => {
          this.setFormData(this.allProfile);
        });
      } else {
        this.setFormData(this.allProfile);
      }
      this.searchResults = this.countries;
      this.searchCountry = this.form.country ? this.form.country.name : "";
    },
    setFormData(profile) {
      if (profile) {
        this.form.country = profile.country;
        this.form.city = profile.city;
        this.key++;
      }
    },
    onNext(event) {
      event.preventDefault();
      let obj = {
        country: this.form.country.id,
        city: this.form.city
      };
      this.updateOnboarding(obj, this.form);
    },
    onSelect(e, type) {
      this.form[type] = e;
    },
    getCountry() {
      if (this.searchCountry) {
        this.searchResults = this.countries.filter(country =>
          country.name.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      } else if (this.searchCountry === "") {
        this.searchResults = this.countries;
      } else {
        this.searchResults = [];
      }
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.searchCountry = country.name;
      this.form.country = country;
      this.isInputFocused = false;
    },
    handleOutsideClick(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.isInputFocused = false;
      }
    },
    highlightMatch(countryName) {
      const regex = new RegExp(`(${this.searchCountry})`, "gi");
      return countryName.replace(regex, '<span class="highlight">$1</span>');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
<style lang="scss">
[dir="rtl"] {
  .ob-ed {
    .ob-ed__main {
      .ob-main__sec {
        .ob-sec__location {
          .form-floating {
            .form-control {
              &:not(:placeholder-shown) {
                ~ {
                  label {
                    transform: scale(0.85) translateY(-0.5rem)
                      translateX(5.2rem);
                  }
                }
              }
              &:focus {
                ~ {
                  label {
                    transform: scale(0.85) translateY(-0.5rem)
                      translateX(5.2rem);
                  }
                }
              }
            }
          }
          .lx-select-dd {
            .lx-select__div {
              .lx-select__label {
                transform: scale(0.85) translateY(-0.5rem) translateX(5.2rem);
              }
            }
          }
        }
      }
    }
  }
}
.ob-ed {
  .form-control {
    color: $brand-neutral-900 !important;
  }
  .dropdown-country {
    border-radius: 8px;
    background: $brand-neutral-0;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    .search-results {
      max-height: 198px;
      overflow-y: scroll;
      ul.countries-list__dropdown {
        list-style: none;
        li {
          @include label-large(
            $font-color: $brand-neutral-700,
            $font-weight: 400
          );
          cursor: pointer;
          letter-spacing: 0.25px;
          &:hover {
            background: $brand-primary-100;
          }
          .highlight {
            font-weight: 700;
          }
        }
      }
    }
    .no-results {
      height: auto;
      overflow: hidden;
      p {
        @include body-regular;
        color: $brand-neutral-700;
      }
    }
  }
}
</style>
